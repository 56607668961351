table td pre {
  margin: 0;
}

.progress-bar {
  animation: progressfill 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

@keyframes progressfill {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
}

.dropdown-purple:focus {
  box-shadow: 0 0 0 0.25rem rgb(111, 66, 193, 0.5) !important;
}